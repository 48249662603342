import React from "react";
import "./Navbar.scss";

export const Navbar = () => {
    return(
        <div className="nav_container">
            <div className="title_container">
                <h1 className="title_nav">The Alphabet</h1>
            </div>
        </div>
    );
}