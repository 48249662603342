import React from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import "./Main.scss";
import logo from "../../assets/logo.jpg";

export const Main = () => {
    return (
        <div>
            <Navbar />
            <div className="main_container">
                <div className="flex_cont">
                    <h1 className="title">Hi check my video!</h1>
                    <a href="https://www.youtube.com/@_____FireAI" alt="https://www.youtube.com/@_____FireAI">
                        <img src={logo}/>
                    </a>
                </div>
                <div className="videos_container">
                    <div className="divider" />
                    <h1 className="best_title">My best videos</h1>
                    <div className="videos_c">
                        <iframe src="https://www.youtube.com/embed/oGpLKoNJQxs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe width="100%" src="https://www.youtube.com/embed/nKu3dLYuN3k?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe width="100%" src="https://www.youtube.com/embed/AR6_8STG7YY?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe width="100%" src="https://www.youtube.com/embed/Hf4lvwSGj58?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}